import axios from "axios";

import { API_HOST } from '../configs/constants'

const api = axios.create({
    baseURL: 'http://independencia.imb.br/',
    responseType: "json",
    headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
    }
});

// api.interceptors.request.use(async config => {
//     const token = getToken();
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

//   axios.interceptors.response.use(response => {
//     return response;
//   }, error => {
//     if (error.response) {
//       if (error.response.status === 401) {
//         logout()
//       }
//     } else if (error.request) {
//       console.log('Falha na requisição');
//       console.log(error.request);
//     } else {
//       console.log('Falha na requisição');
//       console.log(error.message);
//     }
//     return error;
//   });

export default api;