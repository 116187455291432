import styled from "styled-components";

import bg from "../../assets/bg.png";

export const Container = styled.div`
  display: flex;
  background-image: url(${bg});
  background-attachment: fixed;
  background-position: center bottom;
  background-repeat: repeat-x;
  width: 100vw;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
`;

export const HeaderImg = styled.img`
  width: 700px;
  height: 58px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: 73px;
  color: #707070;
  font-size: 25px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #707070;
`;

export const ContentData = styled.div`
  display: flex;
  flex: 1;
`;
export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1em;
  max-height: 90vh;
`;

export const Type = styled.div`
  font-size: 20px;
`;
export const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: black;
`;

export const Description = styled.div`
  flex: 0.3;
  font-size: 18px;
  margin-top: 1em;
  color: black;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  color: black;
  white-space: pre-wrap;
`;

export const MetaTitle = styled.div`
  font-weight: bold;
`;

export const ContentRight = styled.div`
  display: flex;
  flex: 3;
`;

export const ImageLargeDiv = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
`;

export const ImageLarge = styled.img`
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 90%;
  /* object-fit: cover;
    object-position: bottom; */
`;
