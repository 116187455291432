import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../services/api";
import topoImg from "../../assets/header-img.png";
import ReactPlayer from "react-player";

import {
  Container,
  Content,
  Header,
  ContentData,
  Footer,
  HeaderImg,
  ContentLeft,
  ContentRight,
  Description,
  ImageLargeDiv,
  ImageLarge,
  ThumbsDiv,
  ThumbsImg,
  Type,
  Title,
  strong,
  Meta,
} from "./styles";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Home = () => {
  let [delay, setDelay] = useState(5000);
  const maxPagesHouses = 5;
  const delayFast = 5000;
  const delaySlow = 8000;
  const autoplay = true;

  const [showVideo, setShowVideo] = useState(false);

  const [currentHouse, setCurrentHouse] = useState(0);
  const [pageHouse, setPageHouse] = useState(1);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [houses, setHouses] = useState([]);
  const [house, setHouse] = useState(null);

  const [url, setUrl] = useState(null);
  const [loop, setLoop] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoPlayed, setVideoPlayed] = useState(null);
  const [pageVideo, setPageVideo] = useState(1);

  const [videoTotalPages, setVideoTotalPages] = useState(0);
  const [propertiesTotalPage, setPropertiesTotalPages] = useState(0);

  useEffect(() => handleGetHouses(), []);
  useEffect(() => handleGetHouses(), [pageHouse]);
  // useEffect(() => handleGetVideos(), []);

  useEffect(() => handleGetPropertiesCount(), []);
  useEffect(() => handleGetVideosCount(), []);

  useEffect(() => showVideo && setUrl(video[0].content), [showVideo]);

  // useEffect(() => {
  //   if (videoPlayed && videoPlayed.played === 1) {
  //     console.log("acabou o video");
  //     if (pageVideo >= videoTotalPages) {
  //       setPageVideo(1);
  //     } else {
  //       setPageVideo(pageVideo + 1);
  //     }
  //     setShowVideo(!showVideo);
  //     setVideoPlayed(null);
  //     setUrl(null);
  //   }
  // }, [videoPlayed]);

  const isVideoPageReachedLimit = () => {
    if (pageVideo >= videoTotalPages) {
      setPageVideo(1);
    } else {
      setPageVideo(pageVideo + 1);
    }
  };

  const onVideoDone = () => {
    isVideoPageReachedLimit();

    setShowVideo(!showVideo);
    setVideoPlayed(null);
    setUrl(null);
  };

  useInterval(() => {
    if (house && !showVideo) {
      nextPhoto();
    }
  }, delay);

  useEffect(() => {
    if (houses.length) {
      resetHouse();
      setHouse(houses[0]);
    }
  }, [houses]);

  useEffect(() => {
    if (house) {
      setCurrentPhoto(0);
      if (house.photos.length < 3) {
        setDelay(delaySlow);
      } else {
        setDelay(delayFast);
      }
    }
  }, [house]);

  useEffect(() => {
    houses.length && setHouse(houses[currentHouse]);
  }, [currentHouse]);

  const handleGetHouses = async () => {
    try {
      const { data } = await api.get(
        `https://independencia.imb.br/tv/imoveis.json?page=${pageHouse}`
      );
      setHouses(data);
    } catch (e) {
      console.log(e);
    }
  };

  // const handleGetVideos = async () => {
  //   try {
  //     const { data, config } = await api.get(
  //       `http://independencia.imb.br/tv/videos.json?page=${pageVideo}`
  //     );
  //     console.log(config);
  //     setVideo(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleGetVideos = useCallback(async () => {
    try {
      api
        .get(`https://independencia.imb.br/tv/videos.json?page=${pageVideo}`)
        .then(({ data }) => setVideo(data));
    } catch (e) {
      console.log(e);
    }
  }, [pageVideo]);

  useEffect(() => handleGetVideos(), [pageVideo, handleGetVideos]);

  const handleGetPropertiesCount = async () => {
    try {
      const { data } = await api.get(
        `https://independencia.imb.br/tv/imoveis/count`
      );
      setPropertiesTotalPages(Math.ceil(data / 5));
    } catch (e) {}
  };

  const handleGetVideosCount = async () => {
    try {
      const { data } = await api.get(
        `https://independencia.imb.br/tv/videos/count`
      );
      setVideoTotalPages(data);
    } catch (e) {}
  };

  const resetHouse = () => setCurrentHouse(0);

  const nextPhoto = () => {
    if (currentPhoto === house.photos.length - 1) {
      setCurrentPhoto(0);
      nextHouse();
    } else {
      setCurrentPhoto(
        currentPhoto < house.photos.length - 1 ? currentPhoto + 1 : 0
      );
    }
  };

  const nextHouse = () => {
    if (currentHouse === houses.length - 1) {
      setShowVideo(!showVideo);
      nextPageHouse();
    }
    setCurrentHouse(currentHouse < houses.length - 1 ? currentHouse + 1 : 0);
  };

  const nextPageHouse = () => {
    setPageHouse(pageHouse < propertiesTotalPage ? pageHouse + 1 : 1);
  };

  return (
    <Container>
      {showVideo && (
        <>
          <ReactPlayer
            url={url}
            loop={loop}
            width="100%"
            height="100%"
            playing={autoplay}
            // sem o muted, não funciona o autoplay
            muted={true}
            onEnded={() => onVideoDone()}
            config={{
              youtube: {
                playerVars: { autoplay: 1, controls: 1 },
              },
            }}
          />
        </>
      )}
      {!showVideo && (
        <Content>
          <Header>
            <HeaderImg src={topoImg} />
          </Header>
          <ContentData>
            <ContentLeft>
              {house && (
                <>
                  <Type>{house.category.name}</Type>
                  <Title>{house.name}</Title>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: house.description.replace(/(<([^>]+)>)/gi, ""),
                    }}
                  />

                  {(house.footage !== "0" || house.footage === "") && (
                    <Meta>
                      <strong>Metragem:</strong> {house.footage} m2
                    </Meta>
                  )}
                  {house.rooms > 0 && (
                    <Meta>
                      <strong>Quartos:</strong> {house.rooms}
                    </Meta>
                  )}
                  {house.suites > 0 && (
                    <Meta>
                      <strong>Suites:</strong> {house.suites}
                    </Meta>
                  )}
                  {house.spaces > 0 && (
                    <Meta>
                      <strong>Vagas de garagem:</strong> {house.spaces}
                    </Meta>
                  )}
                </>
              )}
            </ContentLeft>
            <ContentRight>
              <ImageLargeDiv>
                {house && (
                  <ImageLarge
                    src={`http://independencia.imb.br${house.photos[currentPhoto].file.capa.url}`}
                    alt=""
                  />
                )}
              </ImageLargeDiv>
            </ContentRight>
          </ContentData>
          <Footer>
            www.independencia.imb.br | Foto {currentPhoto + 1} de{" "}
            {house && house.photos.length}{" "}
          </Footer>
        </Content>
      )}
    </Container>
  );
};

export default Home;
